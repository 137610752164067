import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import underscore from 'vue-underscore';
import AOS from 'aos'
import 'aos/dist/aos.css'
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)
Vue.config.productionTip = false
Vue.prototype.$url = 'https://api.marinasamont.com' //variable global
Vue.prototype.$lang =  navigator.languages[1] // navigator.userLanguage; 
Vue.use(VueViewer)
Vue.use(underscore);

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  mounted() {
    AOS.init()
  },
}).$mount('#app')
