<template>
    <div>
        <section class="container-fluid " >
            <Transition  name="fade" appear >
                <div class="row p-md-5" style="background-color:black;" >
                    <div class="col-8 offset-2" style="padding: 0" data-aos="fade-left">
                        <div class="row ">
                            <div class="col-12 box-2" >
                                <img src="/images/marinasamont-1.jpg" alt="Marina SaMont" class="img-fluid"  />
                                <h2 class="title-subtitle pb-0 m-0" style="font-weight:bold;color:white;font-size:3rem">Marina SaMont</h2>
                                <p style="letter-spacing: 4px;color:white;padding-bottom: 2rem;font-size: 14px !important;font-style:italic">Monterrey, México 1985</p>
                                
                                <p v-if="$lang == 'es'" style="line-height:1.5rem" class="text-justify" v-html="content.bio_es"></p>
                                <p v-else v-html="content.bio_en" style="line-height:1.5rem" class="text-justify" ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </section>
       
        <section class="container card" >
            <Transition name="fade" appear>
                <div class="row pt-5" >
                    <div class="col-12 text-center" >
                        <h1 class="subtitle text-uppercase" v-if="$lang == 'en'">Artist Statement</h1>
                        <h1 class="subtitle text-uppercase" v-else>Declaración de Artista</h1>
                    </div>
                    <div class="col-12">
                        <p style="white-space: pre-line;font-size: 2rem !important;text-align: left;
                            line-height: 39px;font-family:'Times New Roman';padding-bottom:1rem;padding-right:1rem"  
                            class="wrapped-image" v-html="$lang=='es' ? content.small_statement_es : content.small_statement_en">
                        </p>
                        </p>
                        <div class="wrapped-text">
                            <p style="white-space: pre-line;line-height:1.5rem" class="text-justify" v-html="$lang=='es' ? content.statement_es : content.statement_en"></p>
                        </div>
                    </div>
                </div>
            </Transition>
        </section>
    </div>
</template>

<script >
import moment from 'moment';
import axios from 'axios'
import {_} from 'vue-underscore';
export default {
    name: 'ArtistComponent',

    data(){
        return{
            artist: 'Marina SaMont',
            profesional: {},
            exhibitions: {},
            studies: {},
            books:{},
            press:{},
            content:[]
        }
    },
    created(){
        this.getArtistData()
        this.getExhibitions()
        this.getProfesional()
        this.getStudies()
        this.getBooks()
        this.getPress()
    },
  
    methods:{
        format(date,format){
            return moment(date).format(format)
        },

        getArtistData(){
            axios.get(this.$url+'/artist',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.content = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getExhibitions(){
            axios.get(this.$url+'/exhibitionsp',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.exhibitions = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getProfesional(){
            axios.get(this.$url+'/profesional',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.profesional = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getStudies(){
            axios.get(this.$url+'/studies',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.studies = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getBooks(){
            axios.get(this.$url+'/books',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.books = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getPress(){
            axios.get(this.$url+'/press',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.press = res.data
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
}
</script>