<template>
    <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white">
        <div class="container-fluid">
            <router-link  class="navbar-brand m-auto" to="/" >
                <h1 class="logo-text">MARINA SAMONT</h1>
            </router-link>
            <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" 
                data-bs-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse text-end" ref="navbar" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto  mb-2 mb-lg-0" style="margin-left:auto">
                    <li class="nav-item">
                        <router-link class="nav-link active" to="/portfolio">{{$lang == 'es' ? 'Portafolio' : 'Portfolio'}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/artist">{{$lang == 'es' ? 'Artista' : 'Artist'}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/exhibitions">{{$lang == 'es' ? 'Exhibiciones' : 'Exhibitions'}}</router-link>
                    </li>
                    <li class="nav-item" v-show="false">
                        <router-link class="nav-link" to="/behind-the-work">{{$lang == 'es' ? 'Proceso Creativo' : 'Behind the Work'}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/shop">{{$lang == 'es' ? 'Tienda' : 'Shop'}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contact">{{$lang == 'es' ? 'Contacto' : 'Contact'}} </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script >
import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
import InstagramIcon from 'vue-material-design-icons/Instagram.vue'

export default {
    name: 'MenuComponent',
    components:{FacebookIcon,InstagramIcon},
    data() {
      return {
        // Initialize scroll position at [0, 0]
        top: 0
      }
    },
    created(){
        this._scrollListener = () => {
          // window.pageX/YOffset is equivalent to window.scrollX/Y, but works in IE
          // We round values because high-DPI devies can provide some really nasty subpixel values
          this.top = Math.round(window.pageYOffset)
        }

        // Call listener once to detect initial position
        this._scrollListener()

        // When scrolling, update the position
        window.addEventListener('scroll', this._scrollListener)
    },
    beforeDestroy() {
      // Detach the listener when the component is gone
      window.removeEventListener('scroll', this._scrollListener)
    },
  
    watch: {
        $route() {
            //this.$refs.navbar.click(); //click on route change
            this.$refs.navbar.setAttribute('class','collapse navbar-collapse text-end');
        },
    }
}
</script>