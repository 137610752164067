<template>
    <section class="container ">
        <div class="row top"><div class="col-12"></div></div>
        
        <Transition  name="fade" appear >
            <div class="row pb-5"  >
                <div class="text-center col-12" >
                    <div  data-aos="fade-left" >
                        <h1 v-if="$lang == 'en'" class="title-subtitle">Order #{{id}}<br><span style="font-size:10px">Confirmed </span></h1>
                        <h1 v-else class="title-subtitle">Orden #{{id}}<br><span style="font-size:10px">Confirmada </span></h1>
                    </div>
                </div>
            </div>  
        </Transition>
    
        <div class="row" data-aos="fade-up" >
            <div class="col-12 col-md-7">
                <h5 class="text-uppercase">{{$lang=='es' ? 'Detalles de la Orden' : ' Order details'}}</h5>

                
            </div>
            <div class="col-12 col-md-5">
                <div class="row">
                    <div class="col-3">
                        <img :src="$url+'/'+item.featured" :alt="item.title" class="img-fluid rounded border" />
                    </div>
                    <div class="col-6">
                        {{item.title}}<br>
                        <small style="font-size:12px">{{item.dimensions}}</small>
                    </div>
                    <div class="col-3 text-end">
                        $ {{Number(item.price) + Number(shipping)}}
                    </div>
                </div>
                
                <div class="row pt-5 mt-5">
                    <div class="col-6">Subtotal</div>
                    <div class="col-6 text-end">${{Number(item.price) + Number(shipping)}}</div>
                </div>
                <div class="row">
                    <div class="col-6">{{$lang == 'es' ? 'Métodos de envío' : 'Shipping methods'}}</div>
                    <div class="col-6 text-end">DHL ({{item.shipping_package}})</div>
                </div>
                <div class="row pt-3 pb-3">
                    <div class="col-12 text-success text-center">{{$lang == 'es' ? 'Aviso Importante' : 'Important Notice'}}</div>
                    <div class="col-12 " v-if="$lang == 'es'">
                      <p class="border p-2">  
                        <p>Esta obra se encuentra en exhibición en estos momentos. Después de realizar su compra enviaremos un mensaje cuando 
                        la exhibición haya terminado. Solamente a partir de ese momento podremos enviarle su obra.<br>
                        Al realizar su compra recibirá un comprobante con su folio de orden, favor de guardar el comprobante en caso que requiera
                        más adelante. </p>
                    </div>
                    <div class="col-12 alert alert-success m-2" v-else>
                        <span  class=" p-2 " style="font-size:10px;">  This work is currently on exhibition. After making your purchase we will send a message when 
                        the exhibition is over. Only from that moment on we will be able to send you your artwork.<br>
                        After your purchase you will receive a receipt with your order id, please keep the receipt in case we ask for it.</span>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-6"><h3>Total</h3></div>
                    <div class="col-6 text-end"><small class="pe-2">USD</small> 
                        <span style="font-size:20px;color:#000; font-weight:bold">${{Number(item.price) + Number(shipping)}}.00</span></div>
                </div>
              
            </div>
        </div>
       
    </section>
</template>

<script>
export default {
    name: 'OrderComponent',
    data(){
        return{
            id: this.$route.params.id,
            event:{}
        }
    },
    created(){
        this.item = this.$store.state.cart[0]
        this.amount = Number(this.item.price) + Number(this.shipping)
        this.myItems.push({
                name: this.item.title,
                description: this.item.dimensions + ' '+ this.item.media_en,
                quantity: "1",
                price: Number(this.item.price)+Number(this.shipping),
                currency: "USD"
                })
    },
    methods:{
       paymentauthorized(event) {
            // Your response
            console.log('AUTORIZADO')
            console.log(event); 
            this.$router.push('/order/'+event.orderID)
          //  window.location(event.returnUrl)
        },
        paymentcompleted(event) {
            // Your response
            console.log('COMPLETADO')
            console.log(event); 
        },
        paymentcancelled(event) {
            // Your response
            console.log('CANCELADO')
            console.log(event); 
        }
    }
}
</script>