<template>
    <section>
        <Transition  name="fade" appear >
            <div class="container-fluid p-0 m-0 slider" >
                <div class="carousel slide carousel-fade border-bottom"  data-aos="fade-left" id="carousel" data-bs-ride="carousel" data-bs-pause="false" data-bs-touch="false">
                    <div class="carousel-inner">
                        <div class="carousel-item p-0" 
                                data-bs-interval="5000" :class="[slide.id == 24 ? 'active' : '']"  v-for="(slide,z) in slides" :key="z" >
                            <div :style="{ 'background-image': 'linear-gradient( rgba(0,0,0,.3), rgba(0,0,0,.3) ), url(' + $url+'/'+slide.image + ')'}" style="background-size:cover; height:100%" >
                                <div class="slide-text">
                                    <h1 class="p-3 text-nowrap">Marina SaMont</h1>
                                    <h2 style="color:white;letter-spacing: 1px;font-size: 1rem;">contemporary surrealist artist</h2>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>
       
        <div class="container statement position-relative vl-box" data-aos="fade-up">
            <div class="row">
                <div class="col-12 " style="height:7rem">
                    <div class="vl"></div>
                </div>
                <div  class="col-md-8 col-12 offset-md-2 text-center ps-5 pe-5" >
                    <p v-if="$lang == 'es'" class="italic">{{content.home_presentation_es}}</p>
                    <p v-else class="italic">{{content.home_presentation_en}}</p>
                    <p class="text-end italic signature">Marina SaMont</p>
                </div>
            </div>
        </div>
      
        <div class="container" data-aos="fade-up">
            <div class="row pt-3">
                <div class="col-12"><h3 class="title-subtitle text-center bold">{{ featured.title }}</h3></div>
                <div class="col-12 pt-3 pb-5">
                    <img :src="$url + '/' + featured.featured" class="img-fluid wrapped-image" :alt="featured.title" />
                    <div class="wrapped-text">
                        <p style="white-space: pre-line">
                            {{ $lang == 'es' ? featured.description_es : featured.description_en }}
                        </p>
                    </div>
                    <div class="text-center pt-5">
                        <router-link to="/portfolio" class="btn btn-black text-uppercase">
                            {{$lang == 'es' ? 'Ver Colección Exclusiva' : 'View Exclusive Collection'}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'HomeComponent',
    data(){
        return{
            slides:[],
            content:[],
            featured:{}
        }
    },
    created(){
        this.fetchSlides()
        this.getArtistData()
        this.getFeatured()
    },
   
    methods: {
        fetchSlides() {
            axios.get(this.$url+'/sliders',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.slides = res.data.sort((a, b) => b.id - a.id)
            }).catch((err)=>{
                console.log(err)
            })
        },
        getArtistData(){
            axios.get(this.$url+'/artist',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.content = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getFeatured(){
             axios.get(this.$url+'/featured',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.featured = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>
                                                                                                                        