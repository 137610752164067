<template>
    <section class="container ">
        <div class="row" data-aos="fade-up">
            <div class="col-12">
                <div class="row pb-5" v-for="(item, x) in all" :key="x">
                    <div class="col-12 pb-5">
                        <router-link :to="'/artwork/'+item.slug" style="text-decoration: none;">
                            <img :src="$url + '/' + item.featured" class="img-fluid wrapped-image" :alt="item.title" />
                            <div class="wrapped-text">
                                <h3 class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p style="white-space: pre-line;font-style:italic;" >
                                    {{ $lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}<br>
                                    {{ item.year }}
                                </p>
                                <p class="d-none d-md-block"  style="white-space: pre-line">
                                    {{ $lang == 'es' ? item.description_es.substr(0, 800) : item.description_en.substr(0, 800) }} ...
                                </p>
                                <button type="submit" class="btn btn-black text-uppercase d-none d-md-block">{{$lang == 'es' ? 'Ver obra' : 'View artwork'}}</button>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'FigurativeComponent',
    
    data(){
        return{
            col1:{},
            col2:{},
            col3:{},
            all:{},
        }
    },
    created(){
        this.getArtworks()
    },
  
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/exclusive',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.col1 = res.data.col1
                this.col2 = res.data.col2
                this.col3 = res.data.col3
                this.all = res.data.all
            }).catch((err)=>{
                console.log(err)
            })
        },
        
    },
    
}
</script>