import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('lang'),
    cart: JSON.parse(localStorage.getItem('cart')) == null? [] : JSON.parse(localStorage.getItem('cart'))
  },
  mutations: {
    setLang(state,lang){
      state.lang = lang
    },
    setCart(state,cart){
      state.cart = cart
    }
  },
  actions: {
    saveCart({commit},cart){
      commit('setCart',cart)
      localStorage.setItem('cart',JSON.stringify(cart))
    },
    saveLang({commit},lang){
      commit('setLang',lang)
      localStorage.setItem('lang',lang)
    }
  },
  modules: {
  }
})
