<template>
    <section class="container-fluid shop">
        <div class="row top"><div class="col-12"></div></div>
        
        <Transition  name="fade" appear >
            <div class="row pb-5" >
                <div class="text-center col-12 " >
                    <div  data-aos="fade-left" >
                        <h1 v-if="$lang == 'en'" class="title-subtitle">Cart</h1>
                        <h1 v-else class="title-subtitle">Carrito</h1>
                    </div>
                </div>
            </div>  
        </Transition>
    
        <div class="row" data-aos="fade-up" >
            <div class="col-12 col-md-7 m-auto" v-if="cart.length > 0">
                <div class="row" v-for="(item,x) in cart" :key="x">
                    <div class="col-3">
                        <img :src="item.image" :alt="item.title" class="img-fluid" />
                    </div>
                    <div class="col-9">
                        {{item.title}}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-7 m-auto" v-else style="height:50vh">
                <div class="row m-auto text-center" v-if="$lang == 'es'">
                    <h2 class="title-subtitle">Tu carrito está vacío</h2>
                    <router-link to="/shop" class="btn btn-sm btn-red">Ver obras disponibles</router-link>
                </div>
                <div class="row m-auto text-center" v-else>
                    <h2 class="title-subtitle">Your cart is empty</h2>
                    <router-link to="/shop" class="btn btn-sm btn-red btn-block">View available artwork</router-link>
                </div>
            </div>
        </div>
       
    </section>
</template>

<script>

export default {
    name: 'CartComponent',
    data(){
        return{
           cart: []
        }
    },
    created(){
        this.cart = this.$store.state.cart
        console.log(this.cart)
    },
    methods:{
       
    }
}
</script>