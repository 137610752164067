<template>
    <section class="container ">
        <div class="row top"><div class="col-12"></div></div>
        
        <Transition  name="fade" appear >
            <div class="row pb-5"  >
                <div class="text-center col-12" >
                    <div  data-aos="fade-left" >
                        <h1 v-if="$lang == 'en'" class="title-subtitle">Checkout</h1>
                        <h1 v-else class="title-subtitle">Procesa compra</h1>
                    </div>
                </div>
            </div>  
        </Transition>
    
        <div class="row" data-aos="fade-up" >
            <div class="col-12 col-md-7">
                <form>
                    <h5 class="text-uppercase">{{$lang=='es' ? 'Contacto' : 'Contact'}}</h5>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control form-control-pay" required v-model="contact.email" :placeholder=" $lang == 'es' ? 'Contacto':'Contact' ">
                                <label for="floatingInput">Email address</label>
                            </div>
                        </div>
                    </div>

                    <h5 class="text-uppercase">{{$lang=='es' ? 'Dirección de Entrega' : 'Shipping Address'}}</h5>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <country-select v-model="shippingAddress.country_code" :country="shippingAddress.country_code" topCountry="MX" required class="form-control form-control-pay" />
                                <label for="floatingInput">{{$lang=='es' ? 'País' : 'Country'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="contact.name" @change= "shippingAddress.recipient_name = contact.name +' '+ contact.lastname" >
                                <label for="floatingInput">{{$lang=='es' ? 'Nombre' : 'Name'}}</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="contact.lastname"  @change= "shippingAddress.recipient_name = contact.name +' '+ contact.lastname" >
                                <label for="floatingInput">{{$lang=='es' ? 'Apellidos' : 'Last Name'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" v-model="contact.company">
                                <label for="floatingInput">{{$lang=='es' ? 'Empresa (opcional)' : 'Company (optional)'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="shippingAddress.line1">
                                <label for="floatingInput">{{$lang=='es' ? 'Dirección' : 'Address'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="shippingAddress.line2">
                                <label for="floatingInput">{{$lang=='es' ? 'Número externo, Apartamento, etc (opcional)' : 'External Number, Apartment, etc (optional)'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="shippingAddress.postal_code" >
                                <label for="floatingInput">{{$lang=='es' ? 'Código Postal' : 'Zip Code'}}</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="shippingAddress.city" >
                                <label for="floatingInput">{{$lang=='es' ? 'Ciudad' : 'City'}}</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <region-select class="form-control form-control-pay" required v-model="shippingAddress.state" :country="shippingAddress.country_code" :region="shippingAddress.state" />
                                <label for="floatingInput">{{$lang=='es' ? 'Estado' : 'State'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control form-control-pay" required v-model="shippingAddress.phone">
                                <label for="floatingInput">{{$lang=='es' ? 'Teléfono' : 'Phone'}}</label>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <div class="col-12 col-md-5">
                <div class="row">
                    <div class="col-3">
                        <img :src="$url+'/'+item.featured" :alt="item.title" class="img-fluid rounded border" />
                    </div>
                    <div class="col-6">
                        {{item.title}}<br>
                        <small style="font-size:12px">{{item.dimensions}}</small>
                    </div>
                    <div class="col-3 text-end">
                        $ {{Number(item.price) + Number(shipping)}}
                    </div>
                </div>
                
                <div class="row pt-5 mt-5">
                    <div class="col-6">Subtotal</div>
                    <div class="col-6 text-end">${{Number(item.price) + Number(shipping)}}</div>
                </div>
                <div class="row">
                    <div class="col-6">{{$lang == 'es' ? 'Métodos de envío' : 'Shipping methods'}}</div>
                    <div class="col-6 text-end">DHL ({{item.shipping_package}})</div>
                </div>
                <div class="row pt-3 pb-3">
                    <div class="col-12 text-success text-center text-uppercase">{{$lang == 'es' ? 'Aviso Importante' : 'Important Notice'}}</div>
                    <div class="col-12 " v-if="$lang == 'es'">
                      <p class="border p-2">  
                        <p>Esta obra se encuentra en exhibición en estos momentos. Después de realizar su compra enviaremos un mensaje cuando 
                        la exhibición haya terminado. Solamente a partir de ese momento podremos enviarle su obra.<br>
                        Al realizar su compra recibirá un comprobante con su folio de orden, favor de guardar el comprobante en caso que requiera
                        más adelante. </p>
                    </div>
                    <div class="col-12 alert alert-success m-2" v-else>
                        <span  class=" p-2 " style="font-size:10px;">This work is currently on exhibition. After making your purchase we will send a message when 
                        the exhibition is over. Only from that moment on we will be able to send you your artwork.<br>
                        After your purchase you will receive a receipt with your order id, please keep the receipt in case we ask for it.</span>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-6"><h3>Total</h3></div>
                    <div class="col-6 text-end"><small class="pe-2">USD</small> 
                        <span style="font-size:20px;color:#000; font-weight:bold">${{Number(item.price) + Number(shipping)}}.00</span></div>
                </div>
                <div class="row pt-5">
                    <div class="col-12 text-center">
                        <button type="button" @click="createPayment()">paypal button</button>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'CheckoutComponent',
    data(){
        return{
            contact:{},
            billing:{},
            shipping:110,
            item:{},
            shippingAddress:{},
            amount: 0,
            myItems: []
        }
    },
    created(){
        this.item = this.$store.state.cart[0]
        this.amount = Number(this.item.price) + Number(this.shipping)
        this.myItems.push({
                name: this.item.title,
                description: this.item.dimensions + ' '+ this.item.media_en,
                quantity: "1",
                price: Number(this.item.price)+Number(this.shipping),
                currency: "USD",
                sku: '100'+this.item.id+this.item.year
                })
    },
    methods:{
        createPayment() {
            axios.post(this.$url+'/paypal',{user:this.contact.email,cart:this.myItems,shippingAddress:this.shippingAddress},{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                window.location.href = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
}
</script>