var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('MenuComponent',{attrs:{"data-aos":"fade-down","data-aos-delay":"200"}}),_c('router-view',{staticStyle:{"margin-top":"6rem"}}),(_vm.$route.name == 'home' || 
                               _vm.$route.name == 'artist' || 
                               _vm.$route.name == 'contact' || 
                               _vm.$route.name == 'sketches' || 
                               _vm.$route.name == 'paintings' || 
                               _vm.$route.name == 'events' || 
                               _vm.$route.name == 'shop'
                                )?_c('SubscriptionComponent',{attrs:{"data-aos":"fade-left","data-aos-delay":"200"}}):_vm._e(),_c('div',{staticClass:"row pt-5"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"col-12"},[_c('ul',{staticClass:"list-unstyled d-flex justify-content-center a-unstyled"},[_c('li',{staticClass:"small-text text-uppercase p-2"},[_c('router-link',{attrs:{"to":"/privacy-policy"}},[_vm._v(_vm._s(_vm.politicas))])],1),_c('li',{staticClass:"small-text text-uppercase p-2",staticStyle:{"color":"#959595"}},[_vm._v("•")]),_c('li',{staticClass:"small-text text-uppercase p-2"},[_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_vm._v(_vm._s(_vm.terminos))])],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-start p-3 ps-5"},[_c('h3',{staticClass:"small-text pe-3",staticStyle:{"color":"#999"}},[_vm._v("@"+_vm._s(_vm.year)+" MARINA SAMONT")])]),_c('div',{staticClass:"col-6 m-auto"},[_c('ul',{staticClass:"list-unstyled d-flex justify-content-end"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"p-1 icon",attrs:{"href":"https://www.instagram.com/marinasamont"}},[_c('InstagramIcon',{attrs:{"width":"15","alt":"Instagram"}})],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"p-1 icon",attrs:{"href":"https://www.facebook.com/marinasamont"}},[_c('FacebookIcon',{attrs:{"width":"15","alt":"Facebook"}})],1)])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }